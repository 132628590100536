<template>
  <RecommendationCarousel
    :recommendation-query="{
      widgetId: widgetId,
      limit: 16,
      personalisation: true,
    }"
    :subtitle="subtitle"
    :title="title"
    :tracking-data="{
      list: listTrackingData,
    }"
    @error="onCarouselNoResults"
    @no-results="onCarouselNoResults"
  />
  <div class="flex justify-center">
    <CTALink :cta-link="ctaLink" width="fixed" />
  </div>
</template>

<script setup lang="ts">
import type { CTALink as CTALinkType } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import { useListTrackingData } from '@backmarket/nuxt-layer-recommendation/useProductTracking'

import CTALink from '../../shared-components/CTALink/CTALink.vue'

type RecommendationsProps = {
  widgetId: string
  title?: string
  subtitle?: string
  ctaLink?: CTALinkType
  blockPosition?: number
}

const props = defineProps<ContentBlockProps & RecommendationsProps>()
const listTrackingData = useListTrackingData({
  blockPosition: props.blockPosition,
})

const emit = defineEmits(['error'])

const onCarouselNoResults = () => {
  emit('error')
}
</script>
