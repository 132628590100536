<template>
  <div class="mx-auto py-6 sm:container">
    <section>
      <h2 class="heading-1 mb-32 pt-16 text-center">Different Product Cards</h2>
      <section id="base" class="mb-32">
        <h3 class="heading-3">Base Product Cards</h3>
        <div class="grid gap-32 md:grid-cols-2 lg:grid-cols-3">
          <HighlightedProductCard
            class="col-span-2"
            :color-swatches="colors"
            description="Subtitle information Up to two lines before truncation begins and the text is cut"
            image-alt="Illustration title"
            image-src="https://www.backmarket.fr/cdn-cgi/image/format%3Dauto%2Cquality%3D75%2Cwidth%3D1920/https://d2e6ccujb3mkqf.cloudfront.net/0106bb6f-3975-4794-b9a0-7d920669484c-1_6699e84c-942e-4ba2-8dd9-5a70fbed78fe.jpg"
            :percentage-difference="-54"
            :price="price"
            :review-rating="review"
            :storage-tags="tags.map((tag) => ({ name: tag, value: tag }))"
            title="Product title Up to two lines before truncation begin"
            url="/p/macbook-pro-2020-13-inch-m1-8-core-and-8-core-gpu-8gb-ram-ssd-256gb/d31e63d0-b6c2-4b6c-8176-b8a50540c4fc#l=12"
            :warranty="warranty"
          />
          <ProductCard
            v-for="index in Array(5)"
            :key="index"
            :color-swatches="colors"
            image-src="https://www.backmarket.fr/cdn-cgi/image/format%3Dauto%2Cquality%3D75%2Cwidth%3D1920/https://d2e6ccujb3mkqf.cloudfront.net/0106bb6f-3975-4794-b9a0-7d920669484c-1_6699e84c-942e-4ba2-8dd9-5a70fbed78fe.jpg"
            :listing-id="id"
            :price="price"
            :review-rating="review"
            :tags="['Bestseller']"
            title="iPhone 12 - Unlocked"
            :url="linkObject.href"
          />
        </div>
      </section>
      <section id="specifications">
        <h3 class="heading-3">Product Cards with specifications</h3>
        <div class="grid gap-32 md:grid-cols-2 lg:grid-cols-3">
          <HighlightedProductCard
            class="col-span-3"
            :color-swatches="colors"
            :image-alt="'Apple MacBook Air 13.3 (Fin 2020)'"
            :image-src="'https://www.backmarket.fr/cdn-cgi/image/format%3Dauto%2Cquality%3D75%2Cwidth%3D1200/https://d2e6ccujb3mkqf.cloudfront.net/34d35fb4-8a2c-4d80-8c6e-b447b1000f0b-1_f64018e4-6f92-4e4a-ab38-53e65a6cb38e.jpg'"
            :percentage-difference="-54"
            :price="price"
            :review-rating="review"
            :specifications="[
              { label: 'Processeur', values: ['Apple M1'] },
              { label: 'Clavier', values: ['AZERTY - Français'] },
              { label: 'RAM', values: ['8 Go'] },
              {
                label: 'storage',
                values: ['128 Go', '256 Go', '512 Go', '1024 Go'],
              },
            ]"
            :title="'Apple MacBook Air 13.3 (Fin 2020)'"
            :url="linkObject.href"
          />
          <ProductCard
            v-for="index in Array(5)"
            :key="index"
            class="col-span-3"
            :color-swatches="colors"
            image-src="https://www.backmarket.com/cdn-cgi/image/format%3Dauto%2Cquality%3D75%2Cwidth%3D3840/https://d2e6ccujb3mkqf.cloudfront.net/5caea418-e82d-412e-8317-d7169502aec5-1_d828f51b-728e-49d0-8b02-e089288aca0d.jpg"
            :listing-id="id"
            :price="price"
            :review-rating="review"
            :specifications="[
              { label: 'Processeur', values: ['Apple M1'] },
              { label: 'Clavier', values: ['AZERTY - Français'] },
              { label: 'RAM', values: ['8 Go'] },
              { label: 'Garantie Commercial', values: [warranty.toString()] },
              {
                label: 'storage',
                values: ['128 Go', '256 Go', '512 Go', '1024 Go'],
              },
            ]"
            :tags="['Bestseller']"
            title="Apple MacBook Pro 16'' (Mid-2019)"
            :url="linkObject.href"
          />
        </div>
      </section>
    </section>
    <section id="recommendations">
      <RecommendationCarousel
        :options="{
          withCrossedPrice: true,
        }"
        :recommendation-query="{
          limit: 4,
          scope: 'product',
          scopeId: 'c96523f6-50d5-426c-80a4-2280caf673c8',
          category: 'cousinsDown',
        }"
        title="Cousins Down for product"
      />
      <RecommendationCarousel
        :recommendation-query="{
          limit: 12,
          scope: 'addToCart',
          scopeId: '183365',
          category: 'crossSellTwo',
        }"
        title="crossSellTwo AddToCart"
      />
      <RecommendationCarousel
        :recommendation-query="{
          limit: 12,
          scope: 'addToCart',
          scopeId: '183365',
          category: 'crossSellProtection',
        }"
        title="crossSellProtection AddToCart"
      />
      <RecommendationCarousel
        :options="{ withAddToCart: true }"
        :recommendation-query="{
          limit: 16,
          widgetId: '62222d583835041f67a98e20',
        }"
        title="By Widget ID"
      />
    </section>
  </div>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'

import type { MonetaryAmount } from '@backmarket/http-api'
import ProductCard from '@backmarket/nuxt-layer-recommendation/ProductCard.vue'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'

import { useNoIndexMeta } from '~/composables/useNoIndexMeta'
import HighlightedProductCard from '~/scopes/product-list/components/HighlightedProductCard.vue'

useNoIndexMeta()
const warranty = 12
const id = 'foo'
const colors = [
  { name: 'Noir', value: '#000000' },
  { name: 'Blanc', value: '#FFFFFF' },
  { name: 'Jaune', value: '#FFFF00' },
  { name: 'Vert', value: '#D9EFD5' },
  { name: 'Rouge', value: '#FF0000' },
  { name: 'Mauve', value: '#E6DAE6' },
]
const linkObject = {
  type: 'internal',
  href: 'https://www.backmarket.com/en-us/p/macbook-pro-2020-13-inch-m1-8-core-and-8-core-gpu-8gb-ram-ssd-256gb/d31e63d0-b6c2-4b6c-8176-b8a50540c4fc#l=12',
  name: '',
  params: { slugV2: 'foo', uuid: 'bar' },
  query: {},
  hash: {},
}
const price: MonetaryAmount = { amount: '719.00', currency: 'USD' }
const review = { count: 746, average: 4 }
const tags = ['Bestseller']

definePageMeta({
  layout: false,
})
</script>
